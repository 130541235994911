export const mediaQuery = `
@media only screen and (max-width: 600px){
    .assistant__container {
        width: 100%  !important;
        bottom: 0 !important; 
        right: 0 !important;
    }
    .assistant__container.show {
        height: 100%;
        min-height: 100%;
        max-height: 100% !important;
        max-width: 100%  !important;
    }
    .assistant__container .widgetContent__container {
        width: 100%;
        max-width: 100%;
    }
    .assistant__container .tooltip__text {
        max-width: 65vw !important;
    }
    .assistant__button {
        z-index: -1 !important;
        bottom: 20px !important;
        right: 20px !important;
    }
    .assistant__button:hover {
        bottom: 23px;
    }
    .assistant__container .tooltip__text {
        right: 110px !important;
        bottom: 25px !important;
    }
    .assistant__container .assistant__content--iframe {
        height: 100%;
        width: 100%;
        border: none;
        border-radius: 0;
    }
    .assistant__container .subCLose__btn {
        display: flex;
    }
    .assistant__container .subFooter--2 {
        box-shadow: none;
    }
    .assistant__container .subFooter--4 {
        width: 100%;
        border-radius: 0;
    }
    
    .assistant__container  .hidden-widget {
        visibility: hidden !important;
        opacity: 0 !important;
    }

    .assistant__container.show  .hidden-widget {
        visibility: visible !important;
        opacity: 1 !important;
    }
}`;
