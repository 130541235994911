import { params } from '../events/movePages';
import {
    APPOINTMENT_CHANNEL_ID,
    BOOK_APPOINTMENT_PARAM,
    ESTIMATE,
    ESTIMATE_ID_PARAM,
    EVENT_ID_PARAM,
    FULL_PAGE,
    IGNORE_IFRAME,
    MOBILE_SCREEN,
    NEW_LEAD_UPLOAD,
    UPLOAD_ID_PARAM
} from '../../utils/constants';
import { getLocalStorage } from '../../utils/localStorage';
import { access_token, appUrl, form, formId, isHiddenOnDesktop, isHiddenOnMobile, leadInfo, picture, primaryColor, setIsModalPopup, tooltip } from '../../utils/variables';
import { loading } from '../../views/pages/loading';
import { openOrCloseWidget } from '../events/programButtonListeners';

function formatSource(source) {
    let result = source.includes('http://')
        ? source.replace('http://', '')
        : source.includes('https://')
            ? source.replace('https://', '')
            : source;

    return result.replace(/[^0-9a-zA-Z.]/g, '');
}

const urlParams = new URLSearchParams(window.location.search);

let landingPageParam = '';
if (urlParams.get(ESTIMATE_ID_PARAM) && !urlParams.get(EVENT_ID_PARAM) && !urlParams.get(UPLOAD_ID_PARAM)) {
    landingPageParam = `&${ESTIMATE_ID_PARAM}=${urlParams.get(ESTIMATE_ID_PARAM)}`;
} else if (!urlParams.get(ESTIMATE_ID_PARAM) && urlParams.get(EVENT_ID_PARAM) && !urlParams.get(UPLOAD_ID_PARAM)) {
    landingPageParam = `&${EVENT_ID_PARAM}=${urlParams.get(EVENT_ID_PARAM)}`;
} else if (!urlParams.get(ESTIMATE_ID_PARAM) && !urlParams.get(EVENT_ID_PARAM) && urlParams.get(UPLOAD_ID_PARAM)) {
    landingPageParam = `&${UPLOAD_ID_PARAM}=${urlParams.get(UPLOAD_ID_PARAM)}`;
}

const selector = document.querySelector('script[data-id="assistant"]');
const book_appointment = selector.getAttribute('book_appointment');

const appointmentChannel = params('appointment_channel_id') ? params('appointment_channel_id') : selector.getAttribute('appointment_channel');


if (urlParams.get(BOOK_APPOINTMENT_PARAM) || book_appointment) {
    landingPageParam += `&${BOOK_APPOINTMENT_PARAM}=true`;
}

const sourceParam =
    params('source') &&
        params('source').length > 0 &&
        params('source') !== 'null'
        ? `&source=${formatSource(params('source'))}`
        : `&source=${document.referrer}`;

const widgetModal = selector.getAttribute('data-popup');
setIsModalPopup(!!widgetModal);
let isModalPopup = "";

if (widgetModal) {
    openOrCloseWidget('add');
    isModalPopup = '&modal-popup=true';
}

const has_source = `&has_source=${(params('source') && params('source').length > 0 && params('source') !== 'null') ? 1 : 0}`;
const mobileParam = MOBILE_SCREEN || !!widgetModal ? '&mobile=true' : '';

const desktopParam = ((params("staff_id") && params("procedure_id") && params(FULL_PAGE)) || params(APPOINTMENT_CHANNEL_ID)) && !MOBILE_SCREEN ? "&desktop=true" : "";

const domainParam = `&domain=${window.location.hostname}`;

const pathParam = `&path=${window.location.pathname + window.location.search
    }`;

const gclidParam = params('gclid') ? `&gclid=${params('gclid')}` : '';

const fbclidParam = params('fbclid') ? `&fbclid=${params('fbclid')}` : '';

const utmMediumParam = params('utm_medium')
    ? `&utm_medium=${params('utm_medium')}`
    : '';
const utmSourceParam = params('utm_source')
    ? `&utm_source=${params('utm_source')}`
    : '';

const utmCampaignParam = params('utm_campaign')
    ? `&utm_campaign=${params('utm_campaign')}`
    : '';

const utmNetworkParam = params('utm_network')
    ? `&utm_network=${params('utm_network')}`
    : '';

const ignoreIframe = params(IGNORE_IFRAME) ? `&${IGNORE_IFRAME}=${params(IGNORE_IFRAME)}` : '';

const staffIdParam = params('staff_id') ? `&staff_id=${params('staff_id')}` : '';

const procedureIdParam = params('procedure_id') ? `&procedure_id=${params('procedure_id')}` : '';

export function generateWidget() {

    const tooltipData = (urlParams.get(BOOK_APPOINTMENT_PARAM) || book_appointment) ? "Book Appointment" : tooltip;

    const isHidden = MOBILE_SCREEN ? isHiddenOnMobile : isHiddenOnDesktop;

    const pictureData = picture;

    if (urlParams.get(NEW_LEAD_UPLOAD)) {
        landingPageParam += `&${UPLOAD_ID_PARAM}=${leadInfo.text_id}`;
    }

    if (staffIdParam) {
        landingPageParam += staffIdParam
    }

    if (procedureIdParam) {
        landingPageParam += procedureIdParam
    }

    if (params(ESTIMATE)) {
        landingPageParam += `&${ESTIMATE}=true`
    }
    if (params(APPOINTMENT_CHANNEL_ID) || appointmentChannel) {
        landingPageParam += `&${APPOINTMENT_CHANNEL_ID}=${params(APPOINTMENT_CHANNEL_ID) || appointmentChannel}`
    }

    if (!params(APPOINTMENT_CHANNEL_ID) && appointmentChannel) {
        landingPageParam += `&appointment_channel_for_floater=true`
    }

    return `
    <div class="assistant__container ${desktopParam ? 'desktop-ver' : ""} ${widgetModal && book_appointment ? 'modal' : ''}">
    <div class="tooltip__text verticalShake hidden  ${isHidden ? 'hidden-widget' : ''}"><span class='tooltip__icon'>&#128073;</span>
        <span class="close-tooltip-btn">
            <svg xmlns="http://www.w3.org/2000/svg" class="tooltip-btn-icon sm" viewBox="0 0 512 512">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368" class="ionicon-fill-none"></path>
            </svg>
        </span>
        <div class="tooltip__content">
            ${tooltipData || "Get instant quote, book appointment and more..."}
        </div>
    </div>
    
    <div class="assistant__button hidden ${isHidden ? 'hidden-widget' : ''}">
        <span class="open-btn">
            ${pictureData ? 
                `<img src=${process.env.GOOGLE_CLOUD_LOGO_URL}${pictureData} width="60px" height="60px" />` : 
                '<svg class="btn-icon" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324" stroke="white" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="40"/><circle cx="250" cy="400" r="20" fill="white"/></svg>'
            }
        </span>
        <span class="close-btn">
            <svg xmlns="http://www.w3.org/2000/svg" class="btn-icon md hydrated" viewBox="0 0 512 512"><path fill="white" d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"></path></svg>
        </span>
    </div>
    
    <div id="widgetContentContainer" class="widgetContent__container animated">
        <span class="subCLose__btn">
            ${widgetModal ? `<ion-icon name="close-outline"></ion-icon>`
            : `<svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144" stroke="white" fill="none" class="ionicon-fill-none"></path>
                </svg>`
        }
        </span>
        <div class="widget__body">
            <iframe data-hj-allow-iframe
            sandbox="allow-forms allow-popups-to-escape-sandbox allow-modals allow-popups allow-scripts allow-same-origin allow-top-navigation-by-user-activation allow-top-navigation"
            class="assistant__content--iframe"
            src="${appUrl}/assistant.html?form=${formId}&token=${access_token}&appUrl=${appUrl}&color=${primaryColor.substring(
            1
        )}${mobileParam}${desktopParam}${sourceParam}${domainParam}${pathParam}${gclidParam}${fbclidParam}${utmMediumParam}${utmSourceParam}${utmCampaignParam}${utmNetworkParam}${ignoreIframe}${has_source}${landingPageParam}${isModalPopup}">
            </iframe>

            ${loading()}
        </div>
    </div>
  </div>
  <div class="overlay__popup" style=${widgetModal && !book_appointment && 'display:none'}></div>
  `;
}
