import parsePhoneNumberFromString, { isValidPhoneNumber } from "libphonenumber-js";
import { CLOSE } from "../../utils/constants";
import { appUrl, form, isOTPTimeOut, phone, primaryColor, projectId, selectedCountry, setAccessToken, setHasLead, setLeadInfo, setSelectedCodeNumber, setSelectedCountry, visitorId } from "../../utils/variables";
import { loadPhoneComponent } from "../../views/pages/instantQuote";
import { handleClickOutsidePagePhone } from "../events/countryCodeListeners";
import { formatAndValidatePhone, toggleCountryCode } from "../events/phoneListeners";
import { toggleCountriesPhone } from "./countryCodeActions";
import { OTPTimer, sendOTPCodeWithTimer } from "./validateDigitsActions";
import { loginPortal } from "../../services/digitsCodeService";
import { initData } from "../../../widget";

export function inputPhoneUI() {
    let tempUIStr = `
        <div class="logo-project">
            <img src="${form?.project?.business_photo || ""}" alt="logo"/>
        </div>
        <div class="title">
            Appointment Booking Login
        </div>
        <p class="description">
            Enter your phone number to book an appointment
        </p>
        <div class="errorText">
        </div>
        <div class="phone__container">
            <div class="phone__wrappers">
                    <div class="card">
                    <div class="phone--element">
                        <span class="phone__valid hidden">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="phone__invalid hidden">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                        <div class="phone__wrapper--input">
                            <span class="phone__countryCode phone" id="phone__countryCode">
                                <div class="phone__selected-code phone">
                                    <img class="flag-icon selected-flag">
                                    <span class="selected-country-code"></span>
                                </div>
                                <div class="phone__countryCode--dropdown phone hidden">
                                    <ul class="phone__countryCode--select"></ul>
                                </div>
                            </span>
                            <input tabindex="-1" id="phone" class="phone--input" type="tel" autocomplete="tel"
                                placeholder="Enter your phone number" inputmode="tel">
                        </div>
                    </div>
                </div>
                <div class="phone__actions">
                    <div class="phoneContinue__btn disabled">
                        <span>Continue</span>
                        <span class="loader__icon">
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                        </span>
                        <div class="loading__wrapper hidden">
                            <div class="loader">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                   
  `;
    let tempDom = document.querySelector('.wrapper-login .wrap-right');
    if (tempDom) {
        tempDom.innerHTML = tempUIStr;
        loadPhoneComponent();
        countryCodeListeners();
        phoneListeners();
    }
}

function renderValidate() {
    let tempUIStr = `
    <div class="validateDigits__container">
        <div class="validateDigits__wrappers">
            <div class="card">
                <div class="validateDigits--title-wrapper">
                    <div class="validateDigits--title-icon">
                        <div class="icon__background" style="background: ${primaryColor}0f;">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                        </div>
                    </div>
                    <div class="validateDigits--title">
                        Enter 4 digit code sent to phone
                    </div>
                    <div class="errorText">
                    </div>
                    <div class="validateDigits--sub-title">
                        Sent to <strong></strong>
                    </div>
                    <div class="validateDigits--resend-otp">
                        <button class="button--no-style">
                        <ion-icon name="refresh-outline"></ion-icon>
                            <u>Resend</u>
                        </button>
                        <div class="validateDigits__resend-timer"></div>
                    </div>
                </div>
                <div class="validateDigits--element">
                    <div class="validateDigits__wrapper--input" tabindex="-1">
                        <input id="validateDigits0" class="validateDigits__wrapper--input-field" maxlength="4"
                            type="text" autocomplete="one-time-code" inputmode="numeric">
                        <input id="validateDigits1" class="validateDigits__wrapper--input-field" maxlength="1"
                            type="text" autocomplete="off" inputmode="numeric">
                        <input id="validateDigits2" class="validateDigits__wrapper--input-field" maxlength="1"
                            type="text" autocomplete="off" inputmode="numeric">
                        <input id="validateDigits3" class="validateDigits__wrapper--input-field" maxlength="1"
                            type="text" autocomplete="off" inputmode="numeric">
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="validateDigits__actions">
            <div class="validateDigitsContinue__btn disabled">
                Continue <span class="loader__icon"><ion-icon name="arrow-forward-outline"></ion-icon></span>
                <div class="loading__wrapper hidden">
                    <div class="loader">
                </div>
            </div>
        </div>
    </div>
    `;
    let tempDom = document.querySelector('.wrapper-login .wrap-right');
    tempDom.innerHTML = tempUIStr;
}

function countryCodeListeners() {
    /* on click code number in dropdown */
    const codes = document.querySelectorAll('.phone__countryCode--option');
    codes.forEach((i) => {
        i.onclick = () => {
            setSelectedCountry(i.getAttribute('data-country'));
            setSelectedCodeNumber(i.getAttribute('data-country-code'));

            /* Change selected flag icon */
            document.querySelectorAll('.selected-flag').forEach((j) => {
                j.setAttribute(
                    'src',
                    `${appUrl}/assets/images/svg/${i
                        .getAttribute('data-country')
                        .toLowerCase()}.svg`
                );
            });

            /* Change selected country code */
            document.querySelectorAll('.selected-country-code').forEach((j) => {
                j.textContent = `+${i.getAttribute('data-country-code')}`;
            });
            toggleCountriesPhone(CLOSE);
        };
    });

    /* Click outside to close code number country dropdown PHONE screen*/
    document.addEventListener('click', handleClickOutsidePagePhone);
}

function phoneListeners() {
    formatAndValidatePhone();
    toggleCountryCode();

    document.getElementById('phone').addEventListener('focus', () => {
        let oldPhoneElement = document.querySelector(".phone--element.old-phone");
        if (oldPhoneElement) {
            oldPhoneElement.style.backgroundColor = "#fff";
        }
    });

    document.getElementById('phone').addEventListener('blur', () => {
        let oldPhoneElement = document.querySelector(".phone--element.old-phone");
        if (oldPhoneElement) {
            oldPhoneElement.style.backgroundColor = "#ddd";
        }
    });

    const phoneElement = document.querySelector(
        '.phone__container .phone--element'
    );

    document.getElementById('phone').onfocus = () => {
        phoneElement.style.borderColor = `${primaryColor}`;
    };

    document.getElementById('phone').onblur = () => {
        phoneElement.style.borderColor = `lightgrey`;
    };

    if (phoneElement) {
        phoneElement.addEventListener('click', (e) => {
            e.preventDefault();
            let error = false;
            let input = document.querySelector('.phone--input');

            if (
                !input.value ||
                (input.getAttribute('id') === 'phone' &&
                    !isValidPhoneNumber(input.value, selectedCountry))
            ) {
                input.classList.add('invalid');
                error = true;
            }
            if (error) {
                return;
            }
        });
    }

    /* On click enter */
    const screenPhone = document.querySelector('.wrapper-login');

    if (screenPhone) {
        screenPhone.addEventListener('keydown', (event) => {
            if (
                event.key === 'Enter' &&
                !document
                    .querySelector('.phoneContinue__btn')
                    ?.classList.contains('disabled')
            ) {
                document.querySelector('.phoneContinue__btn')?.click();
            }
        });
    }

    /* on click Continue after input phone number*/
    document.querySelector('.phoneContinue__btn').addEventListener('click', handleClickPhoneContinue);
}


async function handleClickPhoneContinue() {
    const errorElement = document?.querySelector(".wrapper-login .errorText")
    if (errorElement) {
        errorElement.textContent = ""
    }
    const loaderIcon = document.querySelector(
        '.phoneContinue__btn .loader__icon'
    );
    try {
        document
            .querySelector('.phoneContinue__btn')
            ?.classList.add('disabled');
        document
            .querySelector('.phoneContinue__btn .loading__wrapper')
            ?.classList.remove('hidden');
        if (loaderIcon) {
            loaderIcon.innerHTML = '';
            loaderIcon.insertAdjacentHTML(
                'beforeend',
                `<img src="${appUrl}/assets/icons/rolling.png" />`
            );
        }
        if (isOTPTimeOut) {
            await sendOTPCodeWithTimer();
        }
        renderValidate();
        validateDigitsListeners();
        OTPTimer()
        document.getElementById('validateDigits0').focus();
        const phoneNumObj = parsePhoneNumberFromString(
            phone,
            selectedCountry
        );
        if (
            document.querySelector(
                '.validateDigits--sub-title strong'
            )
        ) {
            document.querySelector(
                '.validateDigits--sub-title strong'
            ).innerHTML = phoneNumObj.number;
        }
    } catch (error) {
        if (errorElement) {
            errorElement.textContent = error.response?.data?.message
        }
    } finally {
        if (loaderIcon) {
            loaderIcon.innerHTML = '';
            loaderIcon.insertAdjacentHTML(
                'beforeend',
                `<ion-icon name="arrow-forward-outline"></ion-icon>`
            );
        }
        document.querySelector('.phoneContinue__btn .loading__wrapper')?.classList.add('hidden');

        document.querySelector('.phoneContinue__btn')?.classList.remove('disabled');
    }
}


export function validateDigitsListeners() {
    const allowedKeys = ["Backspace", "Enter", "Tab", "ArrowLeft", "ArrowRight"];

    /* On click enter */
    const screenPhone = document
        .querySelector('.wrapper-login');
    if (screenPhone) {
        screenPhone.addEventListener('keydown', (event) => {
            if (event.key === 'Enter' && !document
                .querySelector('.validateDigitsContinue__btn').classList.contains('disabled')) {
                document
                    .querySelector('.validateDigitsContinue__btn').click();
            }
        });
    }

    /* OTP input fields */
    const validateDigitsDoms = [
        document.getElementById('validateDigits0'),
        document.getElementById('validateDigits1'),
        document.getElementById('validateDigits2'),
        document.getElementById('validateDigits3')];

    const resendOTPBtnDom = document.querySelector('.validateDigits--resend-otp');

    /* on click Continue after input phone number*/
    document
        .querySelector('.validateDigitsContinue__btn')
        .addEventListener('click', async () => {
            const loaderIcon = document.querySelector('.validateDigitsContinue__btn .loader__icon');
            const errorElement = document?.querySelector(".wrapper-login .errorText")
            if (errorElement) {
                errorElement.textContent = ""
            }
            try {
                document
                    .querySelector('.validateDigitsContinue__btn')?.classList.add('disabled');
                document
                    .querySelector('.validateDigitsContinue__btn .loading__wrapper')?.classList.remove('hidden');
                if (loaderIcon) {
                    loaderIcon.innerHTML = '';
                    loaderIcon.insertAdjacentHTML('beforeend', `<img src="${appUrl}/assets/icons/rolling.png" />`);
                }
                let otpCode = '';
                validateDigitsDoms.forEach(element => {
                    otpCode += element.value;
                });
                const tempLeadInfo = await loginPortal(parsePhoneNumberFromString(phone, selectedCountry).number, otpCode, projectId, visitorId);

                setAccessToken(tempLeadInfo.data.access_token);
                const data = tempLeadInfo?.data?.lead;
                setLeadInfo(data)
                initData(form)
                validateDigitsDoms.forEach(element => {
                    element.value = '';
                });
                validateDigitsDoms[0].focus();
            } catch (error) {
                validateDigitsDoms.forEach(element => {
                    element.value = '';
                });
                validateDigitsDoms[0].focus();
                if ((error.response?.data?.message || error) && error.response?.status === 500) {
                    setHasLead(true);
                } else {
                    if (errorElement) {
                        errorElement.textContent = error.response?.data?.message
                    }
                }
            } finally {
                document
                    .querySelector('.validateDigitsContinue__btn')?.classList.remove('disabled--noneOpacity');
                if (loaderIcon) {
                    loaderIcon.innerHTML = '';
                    loaderIcon.insertAdjacentHTML('beforeend', `<ion-icon name="arrow-forward-outline"></ion-icon>`);
                }
                document
                    .querySelector('.validateDigitsContinue__btn .loading__wrapper')?.classList.add('hidden');
                if (validateDigitsDoms[0].value && validateDigitsDoms[1].value && validateDigitsDoms[2].value && validateDigitsDoms[3].value) {
                    document.querySelector('.validateDigitsContinue__btn')?.classList.remove('disabled');
                }
            }
        });

    let pageOTPInputContinueBtn = document.querySelector('.validateDigitsContinue__btn');

    /* OTP input field validation - Only accept number */
    validateDigitsDoms.forEach((element, index) => {
        element.addEventListener('input', (event) => {
            if (validateDigitsDoms[0].value && validateDigitsDoms[1].value && validateDigitsDoms[2].value && validateDigitsDoms[3].value) {
                pageOTPInputContinueBtn?.classList.remove('disabled');
            } else if (!pageOTPInputContinueBtn?.classList.contains('disabled')) {
                pageOTPInputContinueBtn?.classList.add('disabled');
            }
            if (event.data?.length === 1 && validateDigitsDoms[index + 1]) {
                validateDigitsDoms[index + 1]?.focus();
                return;
            }
            if (element.id === 'validateDigits0' && element.value.length > 1) {
                for (let i = 0; i < validateDigitsDoms.length; i++) {
                    if (!validateDigitsDoms[i].value) {
                        validateDigitsDoms[i].value = event.target.value.split('')[event.target.value.length - 1];
                        element.value = event.target.value.split('')[0];
                        break;
                    }
                }
                if (validateDigitsDoms[0].value && validateDigitsDoms[1].value && validateDigitsDoms[2].value && validateDigitsDoms[3].value) {
                    pageOTPInputContinueBtn?.classList.remove('disabled');
                } else if (!pageOTPInputContinueBtn?.classList.contains('disabled')) {
                    pageOTPInputContinueBtn?.classList.add('disabled');
                }
                return;
            }
        });

        element.addEventListener('keydown', (event) => {
            if (event.key === 'Tab') {
                event.stopPropagation();
            }
            if ((isNaN(event.key) || event.key === " ") && !event.ctrlKey && !allowedKeys.includes(event.key)) {
                event.preventDefault();
                return;
            }
            if (event.key === "Backspace") {
                event.preventDefault();
                if (!validateDigitsDoms[index].value && validateDigitsDoms[index - 1]) {
                    validateDigitsDoms[index - 1].focus();
                }
                validateDigitsDoms[index].value = "";
                if (!pageOTPInputContinueBtn?.classList.contains('disabled')) {
                    pageOTPInputContinueBtn?.classList.add('disabled');
                }
                return;
            } else if (element.id === 'validateDigits3' && event.key === "Tab") {
                event.preventDefault();
            }
        });

        element.addEventListener('beforeinput', (event) => {
            if (event.data?.length > 1) {
                event.preventDefault();
                let eventDataArr = event.data.split('').filter(value => { return !isNaN(value) && value !== " "; });
                if (eventDataArr.length > 4) {
                    eventDataArr = eventDataArr.slice(0, 4);
                }
                eventDataArr.forEach((val, pos) => {
                    validateDigitsDoms[pos].value = val;
                    if (validateDigitsDoms[pos + 1]) {
                        validateDigitsDoms[pos + 1].focus();
                    }
                });
            } else if (element.value && event.data) {
                event.preventDefault();
                element.value = event.data;
                if (validateDigitsDoms[index + 1]) {
                    validateDigitsDoms[index + 1].focus();
                }
            }
            if (validateDigitsDoms[0].value && validateDigitsDoms[1].value && validateDigitsDoms[2].value && validateDigitsDoms[3].value) {
                pageOTPInputContinueBtn?.classList.remove('disabled');
            } else if (!pageOTPInputContinueBtn?.classList.contains('disabled')) {
                pageOTPInputContinueBtn?.classList.add('disabled');
            }
        });

        element.addEventListener('paste', (event) => {
            const paste = (event.clipboardData || window.clipboardData).getData('text').replaceAll(' ', '').split("");
            event.preventDefault();
            validateDigitsDoms.forEach((element, index) => {
                if (paste[index] && !isNaN(paste[index])) {
                    element.value = paste[index];
                }
            });
            if (validateDigitsDoms[0].value && validateDigitsDoms[1].value && validateDigitsDoms[2].value && validateDigitsDoms[3].value) {
                pageOTPInputContinueBtn?.classList.remove('disabled');
                validateDigitsDoms[3].focus();
            } else if (!pageOTPInputContinueBtn?.classList.contains('disabled')) {
                pageOTPInputContinueBtn?.classList.add('disabled');
            }
        });
    });

    resendOTPBtnDom.addEventListener('click', async () => {
        sendOTPCodeWithTimer();
    });
}