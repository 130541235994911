import { isHiddenPoweredBy, primaryColor } from '../../utils/variables';

export function loading() {
    let poweredByHtml = isHiddenPoweredBy
        ? ''
        : `
            <span>Powered by <b style="margin-left: 3px;"><u class="powered_by"></u></b></span>
        `;
    return `
    <div class="loading__wrapper">
        <div class="loader">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px"
                height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <rect x="19.5" y="16" width="11" height="68" fill="${primaryColor}">
                    <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline"
                        keyTimes="0;0.5;1" values="-4.400000000000006;16;16"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                    <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline"
                        keyTimes="0;0.5;1" values="108.80000000000001;68;68"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                </rect>
                <rect x="44.5" y="16" width="11" height="68" fill="${primaryColor}">
                    <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline"
                        keyTimes="0;0.5;1" values="0.6999999999999957;16;16"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                    <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline"
                        keyTimes="0;0.5;1" values="98.60000000000001;68;68" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="-0.1s"></animate>
                </rect>
                <rect x="69.5" y="16" width="11" height="68" fill="${primaryColor}">
                    <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline"
                        keyTimes="0;0.5;1" values="0.6999999999999957;16;16"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                    <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline"
                        keyTimes="0;0.5;1" values="98.60000000000001;68;68"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                </rect>
            </svg>
        </div>
        <div class="widget-footer light-bg">
            <span class="subFooter--1"></span>
            <span class="subFooter--2"></span>
            <span class="subFooter--3"></span>
            <span class="subFooter--4">
                ${poweredByHtml}
            </span>
            <span class="subFooter--5"></span>
        </div> 
    </div>
    `;
}
